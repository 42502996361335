import { useIsFreePortal, useHasCrmSuiteStarter } from 'onboarding-scopes';
import { useHasAnyScope } from '../../../hooks/auth';
import { useShouldSeeProTrialsBanner } from '../../../hooks/trials/useShouldSeeProTrialsBanner';
import { useSelectedView } from '../../../hooks/config';
import { useIsStarterDemoRemovalControl } from './useIsStarterDemoRemovalControl';
import { ONBOARDING_VIEWS } from 'onboarding-settings-client/constants/onboardingViews';
export const BILLING_READ_SCOPES = ['billing-read', 'billing-read-restricted'];
export const useNavFooterItems = () => {
  // Invite Link Item
  const showInviteLink = useHasAnyScope(['users-write']);

  // Product Item
  const hasBillingPermissions = useHasAnyScope(BILLING_READ_SCOPES);
  const {
    isLoading: isLoadingTrials,
    shouldSeeProTrialsBanner
  } = useShouldSeeProTrialsBanner();
  const shouldSeeProductLink = hasBillingPermissions && !isLoadingTrials;

  // Demo Link Item
  const selectedView = useSelectedView();
  const hasSelectedView = !!selectedView;
  const isFreePortal = useIsFreePortal();
  const hasCrmSuiteStarter = useHasCrmSuiteStarter();
  const isStarterDemoRemovalControl = useIsStarterDemoRemovalControl();
  const viewsWithDemoLink = [ONBOARDING_VIEWS.CMS, ONBOARDING_VIEWS.MARKETING, ONBOARDING_VIEWS.SALES, ONBOARDING_VIEWS.SERVICE];
  const showDemoLink = (isFreePortal || hasCrmSuiteStarter && isStarterDemoRemovalControl) && hasSelectedView && viewsWithDemoLink.includes(selectedView);
  return {
    shouldSeeNavFooter: showInviteLink || shouldSeeProductLink || showDemoLink,
    showInviteLink,
    hasBillingPermissions,
    showTrialPickerLink: shouldSeeProTrialsBanner,
    isLoadingTrials,
    showDemoLink
  };
};