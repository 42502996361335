export const CATEGORY_IDS = Object.freeze({
  UNMAPPED: 0,
  LANDING_PAGE: 1,
  EMAIL: 2,
  BLOG_POST: 3,
  SITE_PAGE: 4,
  LEGACY_PAGE: 5,
  KNOWLEDGE_ARTICLE: 6,
  BLOG_LISTING_PAGE: 7,
  WEB_INTERACTIVE: 8,
  PORTAL_CONTENT: 9,
  SMS: 10,
  KNOWLEDGE_BASE_ILP: 12,
  CASE_STUDY_ILP: 13,
  PODCAST: 14,
  PODCAST_EPISODE: 15,
  BLOG_ARTICLE: 16,
  QUOTE: 18
});

// TODO: remove labels or at least pass them through I18n

export const blog_listing_page = {
  template_folder: 'blog_listing_page',
  name: 'blog-listing-page',
  api_slug: 'blog-listing-pages',
  label: 'Blog Listing Page',
  dashboard_slug: 'blog-listing-pages',
  new_content_slug: 'new/blog-listing-page',
  id: CATEGORY_IDS.BLOG_LISTING_PAGE,
  generic_label: 'Blog Listing Page',
  label_plural: 'Blog Listing Pages',
  field_name: 'blog_listing_page',
  slug_plural: 'blog-listing-pages',
  slug_singular: 'blog-listing-page'
};
export const blog_post = {
  template_folder: 'blog',
  name: 'blog-post',
  api_slug: 'blog-posts',
  label: 'Blog Post',
  dashboard_slug: 'blog-posts',
  new_content_slug: 'new/blog-post',
  id: CATEGORY_IDS.BLOG_POST,
  generic_label: 'Blog',
  label_plural: 'Blog Posts',
  field_name: 'blog_post',
  slug_plural: 'blog-posts',
  slug_singular: 'blog-post'
};
export const landing_page = {
  template_folder: 'landing_page',
  name: 'landing-page',
  api_slug: 'landing-pages',
  label: 'Landing Page',
  dashboard_slug: 'landing-pages',
  new_content_slug: 'new/landing-page',
  id: CATEGORY_IDS.LANDING_PAGE,
  generic_label: 'Page',
  label_plural: 'Landing Pages',
  field_name: 'landing_page',
  slug_plural: 'landing-pages',
  slug_singular: 'landing-page'
};
export const site_page = {
  template_folder: 'site_page',
  name: 'site-page',
  api_slug: 'site-pages',
  label: 'Website Page',
  dashboard_slug: 'site-pages',
  new_content_slug: 'new/site-page',
  id: CATEGORY_IDS.SITE_PAGE,
  generic_label: 'Website Page',
  label_plural: 'Website Pages',
  field_name: 'site_page',
  slug_plural: 'site-pages',
  slug_singular: 'site-page'
};
export const legacy_page = {
  template_folder: 'legacy_page',
  name: 'legacy-page',
  api_slug: 'legacy-pages',
  label: 'Classic CMS Page',
  dashboard_slug: 'legacy-pages',
  new_content_slug: 'new/legacy-page',
  id: CATEGORY_IDS.LEGACY_PAGE,
  generic_label: 'Classic CMS Page',
  label_plural: 'Classic CMS Pages',
  field_name: 'legacy_page',
  slug_plural: 'legacy-pages',
  slug_singular: 'legacy-page'
};
export const unmapped = {
  template_folder: 'pages',
  name: 'unmapped',
  api_slug: 'unmappeds',
  label: 'Unmapped',
  dashboard_slug: 'unmappeds',
  new_content_slug: 'new/unmapped',
  id: CATEGORY_IDS.UNMAPPED,
  generic_label: 'System',
  label_plural: 'Unmappeds',
  field_name: 'unmapped',
  slug_plural: 'unmappeds',
  slug_singular: 'unmapped'
};
export const email = {
  template_folder: 'email',
  name: 'email',
  api_slug: 'emails',
  label: 'Email',
  dashboard_slug: 'email',
  new_content_slug: 'new/email',
  id: CATEGORY_IDS.EMAIL,
  generic_label: 'Email',
  label_plural: 'Email',
  field_name: 'email',
  slug_plural: 'email',
  slug_singular: 'email'
};
export const sms = {
  template_folder: 'sms',
  name: 'sms',
  api_slug: 'sms',
  label: 'Sms',
  dashboard_slug: 'sms',
  new_content_slug: 'new/sms',
  id: CATEGORY_IDS.SMS,
  generic_label: 'Sms',
  label_plural: 'Sms',
  field_name: 'sms',
  slug_plural: 'sms',
  slug_singular: 'sms'
};
export const knowledge_article = {
  template_folder: '',
  name: 'knowledge-article',
  api_slug: '',
  label: 'Knowledge Article',
  dashboard_slug: '',
  new_content_slug: '',
  id: CATEGORY_IDS.KNOWLEDGE_ARTICLE,
  generic_label: '',
  label_plural: 'Knowledge Articles',
  field_name: 'knowledge_article',
  slug_plural: 'knowledge-article',
  slug_singular: 'knowledge-articles'
};
export const web_interactive = {
  template_folder: '',
  name: 'web-interactive',
  api_slug: '',
  label: 'Web Interactive',
  dashboard_slug: 'calls-to-action',
  new_content_slug: '',
  id: CATEGORY_IDS.WEB_INTERACTIVE,
  generic_label: 'Interactive',
  label_plural: 'Web Interactives',
  field_name: 'web_interactive',
  slug_plural: 'web-interactives',
  slug_singular: 'web-interactive'
};
const KbCategoryDetails = {
  name: 'kb_article_instance_layout',
  label: 'KB Article Instance Layout Page',
  rootApiSlug: 'layout-pages'
};

// @TODO: Update dashboard routes and screenshot preview URLs
export const kb_article_instance_layout = {
  template_folder: '',
  name: KbCategoryDetails.name,
  api_slug: KbCategoryDetails.rootApiSlug,
  label: KbCategoryDetails.label,
  dashboard_slug: KbCategoryDetails.rootApiSlug,
  new_content_slug: `new/${KbCategoryDetails.rootApiSlug}`,
  id: CATEGORY_IDS.KNOWLEDGE_BASE_ILP,
  generic_label: KbCategoryDetails.label,
  label_plural: `${KbCategoryDetails.label}s`,
  field_name: KbCategoryDetails.name,
  slug_plural: KbCategoryDetails.name,
  slug_singular: KbCategoryDetails.name
};
const caseStudyCategoryDetails = {
  name: 'case_study_instance_layout',
  label: 'Case Study Instance Layout Page',
  rootApiSlug: 'layout-pages'
};
export const case_study_instance_layout = {
  template_folder: '',
  name: caseStudyCategoryDetails.name,
  api_slug: caseStudyCategoryDetails.rootApiSlug,
  label: caseStudyCategoryDetails.label,
  dashboard_slug: caseStudyCategoryDetails.rootApiSlug,
  new_content_slug: `new/${caseStudyCategoryDetails.rootApiSlug}`,
  id: CATEGORY_IDS.CASE_STUDY_ILP,
  generic_label: caseStudyCategoryDetails.label,
  label_plural: `${caseStudyCategoryDetails.label}s`,
  field_name: caseStudyCategoryDetails.name,
  slug_plural: caseStudyCategoryDetails.name,
  slug_singular: caseStudyCategoryDetails.name
};
const podcastCategoryDetails = {
  name: 'podcast',
  label: 'Podcast',
  rootApiSlug: 'podcasts'
};
export const podcast = {
  template_folder: '',
  name: podcastCategoryDetails.name,
  api_slug: podcastCategoryDetails.rootApiSlug,
  label: podcastCategoryDetails.label,
  dashboard_slug: podcastCategoryDetails.rootApiSlug,
  new_content_slug: podcastCategoryDetails.rootApiSlug,
  id: CATEGORY_IDS.PODCAST,
  generic_label: podcastCategoryDetails.label,
  label_plural: `${podcastCategoryDetails.label}s`,
  field_name: podcastCategoryDetails.name,
  slug_plural: podcastCategoryDetails.name,
  slug_singular: podcastCategoryDetails.name
};
const podcastEpisodeCategoryDetails = {
  name: 'podcast_episode',
  label: 'Podcast episode',
  rootApiSlug: 'podcasts'
};
export const podcastEpisode = {
  template_folder: '',
  name: podcastEpisodeCategoryDetails.name,
  api_slug: podcastEpisodeCategoryDetails.rootApiSlug,
  label: podcastEpisodeCategoryDetails.label,
  dashboard_slug: podcastEpisodeCategoryDetails.rootApiSlug,
  new_content_slug: podcastEpisodeCategoryDetails.rootApiSlug,
  id: CATEGORY_IDS.PODCAST_EPISODE,
  generic_label: podcastEpisodeCategoryDetails.label,
  label_plural: `${podcastEpisodeCategoryDetails.label}s`,
  field_name: podcastEpisodeCategoryDetails.name,
  slug_plural: podcastEpisodeCategoryDetails.name,
  slug_singular: podcastEpisodeCategoryDetails.name
};
export const blogArticleInstanceLayoutDetails = {
  name: 'blog_article',
  label: 'Blog article',
  rootApiSlug: 'blogarticles'
};
export const blog_article_instance_layout = {
  template_folder: '',
  name: blogArticleInstanceLayoutDetails.name,
  api_slug: blogArticleInstanceLayoutDetails.rootApiSlug,
  label: blogArticleInstanceLayoutDetails.label,
  dashboard_slug: blogArticleInstanceLayoutDetails.rootApiSlug,
  new_content_slug: `new/${blogArticleInstanceLayoutDetails.rootApiSlug}`,
  id: CATEGORY_IDS.BLOG_ARTICLE,
  generic_label: blogArticleInstanceLayoutDetails.label,
  label_plural: `${blogArticleInstanceLayoutDetails.label}s`,
  field_name: blogArticleInstanceLayoutDetails.name,
  slug_plural: blogArticleInstanceLayoutDetails.name,
  slug_singular: blogArticleInstanceLayoutDetails.name
};
export const quoteDetails = {
  name: 'quote',
  label: 'Quote',
  rootApiSlug: 'quotes'
};
export const quote = {
  template_folder: '',
  name: quoteDetails.name,
  api_slug: quoteDetails.rootApiSlug,
  label: quoteDetails.label,
  dashboard_slug: quoteDetails.rootApiSlug,
  new_content_slug: `new/${quoteDetails.rootApiSlug}`,
  id: CATEGORY_IDS.QUOTE,
  generic_label: quoteDetails.label,
  label_plural: `${quoteDetails.label}s`,
  field_name: quoteDetails.name,
  slug_plural: `${quoteDetails.name}s`,
  slug_singular: quoteDetails.name
};

// For historical reasons the `categories` are accessed via their id, name, and field_name in different scenarios
const categories = [blog_listing_page, blog_post, landing_page, site_page, legacy_page, unmapped, email, sms, knowledge_article, web_interactive, kb_article_instance_layout, case_study_instance_layout, podcast, podcastEpisode, blog_article_instance_layout, quote].reduce((_categories, category) => {
  return Object.assign({}, _categories, {
    [category.id]: category,
    [category.name]: category,
    [category.field_name]: category
  });
}, {});
export default categories;