const getBadgeColor = use => {
  switch (use) {
    case 'pantera':
      return 'fill-accent-neutral-default';
    case 'sorbet':
      return 'fill-accent-light-orange-default';
    case 'koala':
      return 'fill-tertiary-default';
    case 'candy-apple':
      return 'fill-alert-default';
    case 'calypso':
      return 'fill-secondary-default';
    case 'marigold':
      return 'fill-accent-yellow-default';
    case 'norman':
      return 'fill-accent-magenta-default';
    case 'beta':
    case 'thunderdome':
      return 'fill-accent-purple-default';
    case 'new':
    case 'oz':
      return 'fill-positive-default';
    case 'lorax':
    case 'free':
    default:
      return 'fill-brand-default';
  }
};
export const CountStyles = {
  display: 'inline-block',
  backgroundColor: 'fill-accent-magenta-default',
  borderInlineWidth: '100',
  borderBlockWidth: '100',
  borderInlineStyle: 'solid',
  borderBlockStyle: 'solid',
  borderInlineColor: 'border-primary-on-fill',
  borderBlockColor: 'border-primary-on-fill',
  borderStartStartRadius: '300',
  borderStartEndRadius: '300',
  borderEndStartRadius: '300',
  borderEndEndRadius: '300',
  paddingInline: '100',
  paddingBlock: '50',
  minBlockSize: '24px',
  minInlineSize: '24px'
};
export const getBadgeStyles = use => {
  return {
    display: 'inline-block',
    borderStartStartRadius: '100',
    borderStartEndRadius: '100',
    borderEndStartRadius: '100',
    borderEndEndRadius: '100',
    minBlockSize: '18px',
    backgroundColor: getBadgeColor(use),
    paddingInline: '200',
    paddingBlock: '25'
  };
};