const ExperimentExposures = () => {
  // EXAMPLE BELOW 👇
  // const isEligibleForOnbc007 = useIsEligibleForOnbc007();

  // useEffect(() => {
  //   if (isEligibleForOnbc007) {
  //     logExposure(ONBC007_TREATMENT_KEY);
  //   }
  // }, [isEligibleForOnbc007]);

  return null;
};
export default ExperimentExposures;